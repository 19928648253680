export const Configuration = {
    agoraAppId: "f30d70cf5ef645198e73184be37170b9", 
    basePath: "https://app.teamblaze.io",
    tos: "http://teamblaze.io/tos/",
    policy: "https://teamblaze.io/privacy-policy/",
    build: "11683",

    awayDelayMs: 300000, //5 min
}

